<template>
    <div class="dirr">
        <v-container style="max-width:1261px !important" :class="$vuetify.breakpoint.xs ? 'con-photo-homee' : ''">
            <div class="panl-title" style="    padding-right: 9px;">
                <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" />
                <a :href="'https://alkafeel.net/photos/?lang='+$i18n.locale"
                    style="    text-decoration: none !important;">
                    <div class="panl-title-text d-inline tl"> {{ $t("title_photos") }}</div>
                </a>
            </div>

            <div style="margin-top: 16px">
                <swiper class="swiper" :options="swiperOptions" v-if="loadingCats">
                    <div class="swiper-paginations"></div>
                    <swiper-slide class="swiper-slide" v-for="item in 10" :key="item">
                        <v-skeleton-loader class="mx-auto" max-width="100%" max-height="90" type="card-avatar, article">
                        </v-skeleton-loader>

                    </swiper-slide>
                </swiper>

                <swiper class="swiper" :options="swiperOptions" v-else>
                    <swiper-slide class="swiper-slide" v-for="cat in photoCats" :key="cat">
                        <div>
                            <a :href="'https://pic.alkafeel.net/sections/'+cat.id+'?lang='+$i18n.locale"
                                style="    text-decoration: none;">
                                <img :src="'https://alkafeel.net/photos/upload/sections/'+cat.photo" alt=""
                                    style="height: 90px;width:100%;border-radius: 4px;">

                                <div class="tl cat_titles">{{cat.title}}</div>
                            </a>
                        </div>
                    </swiper-slide>

                </swiper>






                <swiper class="swiper  ph_sli_home_phone" :options="swiperOptionss">
                    <swiper-slide class="ph_sli_home_phoness">
                        <a v-for="photo in photos.slice(0,4)" :key="photo"
                            :href="'https://pic.alkafeel.net/post/'+photo.id+'?lang='+$i18n.locale">
                            <div class="test">
                                <v-img aspect-ratio="1" :src="'https://alkafeel.net/photos/imager/avif/'+photo.photo+'/MjM1'"> </v-img>

                            </div>
                        </a>
                    </swiper-slide>

                    <swiper-slide class="ph_sli_home_phoness">
                        <a v-for="photo in photos.slice(4,8)" :key="photo"
                            :href="'https://pic.alkafeel.net/post/'+photo.id+'?lang='+$i18n.locale">
                            <div class="test">
                                <v-img aspect-ratio="1":src="'https://alkafeel.net/photos/imager/avif/'+photo.photo+'/MjM1'"></v-img>
                            </div>
                        </a>
                    </swiper-slide>

                    <swiper-slide class="ph_sli_home_phoness">
                        <a v-for="photo in photos.slice(8,11)" :key="photo"
                            :href="'https://pic.alkafeel.net/post/'+photo.id+'?lang='+$i18n.locale">
                            <div class="test">
                                <v-img aspect-ratio="1" :src="'https://alkafeel.net/photos/imager/avif/'+photo.photo+'/MjM1'"> </v-img>
                            </div>
                        </a>
                        <a :href="'https://alkafeel.net/photos/?lang='+$i18n.locale" style="    text-decoration: none !important;">
                            <p class="more_phot_home"> {{ $t("more") }} </p>
                        </a>
                    </swiper-slide>
                    <br>
                    <br>

                    <div class="swiper-pagination swiper-pagination-bullets" style="position: inherit;bottom: 4px !important " slot="pagination"></div>
                </swiper>




                <swiper class="swiper  ph_sli_home" :options="swiperOptionss">

                    <swiper-slide class="ph_sli_home_phoness" v-if="loading" style="position: relative;top: 21px;">
                        <v-row row wrap>
                            <v-col pt-3 style=" max-width:480px;; margin:0 0px;" class="d-none d-md-flex d-lg-none d-lg-flex">
                                <v-skeleton-loader class="mx-auto" height="500" width="480" type="card-avatar, article"></v-skeleton-loader>
                            </v-col>
                            <v-col>
                                <v-layout row wrap>
                                    <v-flex xs6 sm4 md4 py-3 class="ipd_pro_photot" pr-1 v-for="photo in 4" :key="photo">
                                        <v-skeleton-loader class="mx-auto" height="200" width="480" type="card"></v-skeleton-loader>
                                    </v-flex>
                                </v-layout>
                            </v-col>
                        </v-row>
                    </swiper-slide>
                    <swiper-slide v-else>
                        <v-row row wrap>
                            <v-col pt-3 style=" max-width:480px;max-height:500px; margin:0 0px;" class="d-none d-md-flex d-lg-none d-lg-flex   ipadproawx">
                                <a :href="'https://pic.alkafeel.net/post/'+photos[0].id+'?lang='+$i18n.locale" style="text-decoration: none;width:100%;height:100%" class="test ipadproaw">
                                    <v-img aspect-ratio="1" style="height:100%" :src="'https://alkafeel.net/photos/imager/avif/'+photos[0].photo+'/NDYy'"> </v-img>
                                </a>
                            </v-col>
                            <v-col>
                                <v-layout row wrap>
                                    <v-flex xs6 sm4 md4 py-3 class="ipd_pro_photot" v-for="photo in photos.slice(1,7)"
                                        :key="photo">
                                        <a :href="'https://pic.alkafeel.net/post/'+photo.id+'?lang='+$i18n.locale">
                                            <div class="test">
                                                <v-img aspect-ratio="1" :src="'https://alkafeel.net/photos/imager/avif/'+photo.photo+'/MjM1'"></v-img>
                                            </div>
                                        </a>
                                    </v-flex>
                                </v-layout>
                            </v-col>
                        </v-row>
                    </swiper-slide>


                    <swiper-slide>
                        <v-layout row wrap>
                            <v-flex xs6 sm3 style="max-width: 225px;" py-3 v-for="photo in photos.slice(7,20)"
                                :key="photo" class="pic_ho_sli">
                                <a :href="'https://pic.alkafeel.net/post/'+photo.id+'?lang='+$i18n.locale">
                                    <div class="test">
                                        <v-img aspect-ratio="1" :src="'https://alkafeel.net/photos/imager/avif/'+photo.photo+'/MjM1'"> </v-img>
                                    </div>
                                </a>
                            </v-flex>
                            <v-flex xs6 style="max-width: 225px;" class="pic_ho_sli" pt-3>
                                <a :href="'https://alkafeel.net/photos/?lang='+$i18n.locale" style="    text-decoration: none !important;">
                                    <p :style="$vuetify.theme.dark ? 'color: rgb(255, 255, 255)' : 'color: rgb(0, 17, 44)'" style="padding: 92px; text-align: center; font-weight: bold; text-decoration: none !important; position: relative; top: 10px;"> {{ $t("more") }} </p>
                                </a>
                            </v-flex>

                        </v-layout>

                    </swiper-slide>
                    <br>
                    <br>

                    <div class="swiper-pagination swiper-pagination-bullets" style="position: inherit;bottom: 4px !important " slot="pagination"></div>
                </swiper>


            </div>

        </v-container>

    </div>
</template>



<script>
    export default {

        data() {
            return {
                photoCats: [],
                loading: true,
                loadingCats: true,

                photos: [],
                swiperOptionss: {
                    autoplay: {
                        delay: 21900,
                        disableOnInteraction: true,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    mousewheel: false,
                    slidesPerView: 1,


                    breakpoints: {
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 25,
                        },
                        360: {
                            slidesPerView: 1,
                            spaceBetween: 1,
                            width: 360,
                            height: 400
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 25,
                        },
                        1024: {
                            slidesPerView: 1,
                            spaceBetween: 25,
                            // spaceBetween: 23,
                        },
                    }
                },
                swiperOptions: {
                    autoplay: {
                        delay: 16400,
                        disableOnInteraction: true,
                    },
                    mousewheel: false,

                    //   navigation: {
                    //     hiddenClass:'swiper-button-hidden'
                    // },
                    freeMode: true,

                    pagination: {
                        el: '.swiper-paginationx'
                    },
                    keyboard: true,
                    breakpoints: {
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                            mousewheel: true,
                        },
                        360: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                            width: 320,
                            mousewheel: true,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                            mousewheel: true,
                        },
                        1024: {
                            slidesPerView: 7,
                            spaceBetween: 30,
                            //       navigation: {
                            // nextEl: ".swiper-button-next",
                            // prevEl: ".swiper-button-prev",
                            //  navigation: true,
                        },
                    },

                },
                text: ' بعد أن اختتمتْ مشاركتها في فعّاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراعيّة والحيوانيّ',
                des: '     الدوليّ للمنتجات الزراعيّة والحيوانيّة، التي وُصفت بالناجحة والفعّالة بعد أن اختتمتْ مشاركتها في فعاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراعيّة والحيوانيّة، التي وُصفت بالناجحة والفعّال'
            }
        },
        computed: {

        },
        mounted() {

        },


        methods: {
            Gox(u) {
                u

                this.$axios.get("photosGallery/cats", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: "Bearer " + this.$store.state.AdminInfo.token
                        }
                    })
                    .then(res => {
                        res

                    })
                    .catch(() => {

                    });
            },
            base64(code) {
                return btoa(code);
            },

            initialize() {

                this.$axios.get("photosGallery/cats", {})
                    .then(res => {
                        this.loadingCats = false;
                        this.photoCats = res.data.Data;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            getPhoto() {
                var limit;
                // this.loading = true;
                if (/iPad|iPod/i.test(navigator.userAgent)) {
                    // true for mobile device
                    limit = 11;

                } else if (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    limit = 15;
                } else {
                    // false for not mobile device
                    limit = 16;
                }
                this.$axios.get("photosGallery/last/" + limit, {})
                    .then(res => {
                        this.loading = false;
                        this.photos = res.data.Data;
                    })
                    .catch(() => {
                        // this.loading = false;
                    });
            }
        },
        created() {
            this.initialize();
            this.getPhoto();

            // this.getZR()
        },
    }
</script>


<style>
    .cat_titles {

        font-size: 12.8px;
        text-align: center;
        text-decoration: none !important;


        padding-top: 3px;
        padding-bottom: 3px;
        color: #00112C;
        /* font-family: 'el_messiri'; */

    }

    :root {
        --swiper-theme-color: #b1bd52 !important;
    }

    @media screen and (min-width:320px) {

        .ph_sli_home_phoness {
            height: 400px !important;
            position: relative;
            bottom: 12px;
        }

        .con-photo-homee {
            padding: 0px !important;
        }
    }

    .test {
        display: inline-block;
        position: relative;
        width: 235px;
        height: 235px;

    }

    .test::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: #a7b24d;
        top: 34%;
    }

    .pic_ho_sli {
        padding-left: 24px;
    }

    .ph_sli_home_phone {
        display: none;
    }

    @media screen and (max-width: 600px) {
        .ph_sli_home {
            display: none;
        }

        .ph_sli_home_phone {
            display: block !important;
        }

        .pic_ho_sli {
            padding-left: 0px !important;
        }

        .test {
            width: 176px !important;
            height: 176px !important;
            margin-top: 15px;
            padding-right: 4px;
        }
    }
</style>